import { useQuery } from '@apollo/client'
import { useRouter } from 'next/router'
import { ComponentType, useState } from 'react'

import { isUserCreator, PUBLIC_URLS } from '@lib/userUtils'

import { GET_AUTH_USER_AND_OPPORTUNITY } from '@graphql/user/queries'

import { IUser } from '@models/user'

import { OpportunityResponse } from '@components/journey/models'

const withAnonymousUserGuard = (Component: ComponentType) => {
  const AnonymousUserGuard = () => {
    const { push, pathname } = useRouter()
    const [showPage, setShowPage] = useState<boolean>(false)

    useQuery<{ me: IUser } & OpportunityResponse>(GET_AUTH_USER_AND_OPPORTUNITY, {
      fetchPolicy: 'cache-and-network',
      onCompleted({ me, opportunity }) {
        if (PUBLIC_URLS.includes(pathname)) {
          return
        }

        if (!isUserCreator(me)) {
          window.location.href = `https://${process.env.APP_HOST}`
        } else if (opportunity?.completedAt) {
          push('/resources')
        } else if (opportunity) {
          push('/journey')
        } else {
          push('/welcome')
        }
      },
      onError() {
        setShowPage(true)
      },
    })

    return showPage ? <Component /> : null
  }

  AnonymousUserGuard.displayName = `withAnonymousUserGuard(${Component.displayName})`

  return AnonymousUserGuard
}

export default withAnonymousUserGuard
