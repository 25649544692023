import { NextPage } from 'next'

import withAnonymousUserGuard from '@lib/withAnonymousUserGuard'

import { AccessMode, AccessPage } from '@components/AccessPage'

const SignUpPage: NextPage = () => {
  return <AccessPage defaultAccessMode={AccessMode.SignUp} />
}

export default withAnonymousUserGuard(SignUpPage)
